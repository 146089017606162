.tomdetails {
  color: #fff;
}
.tomdetails h5 {
  text-align: center;
}
.retry {
  display: flex;
  justify-content: center;
}
