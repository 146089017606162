.container-temple {
  margin: auto;
  margin-bottom: 50px;
  text-align: left;
  width: 800px;
  margin-top: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media only screen and (max-width: 750px) {
  .container-temple {
    margin-bottom: 50px;
    text-align: left;
    width: 100%;
    margin-top: 40px;
    background-color: rgb(255, 255, 255);
  }
  .coninfo-temple {
    margin-left: 6px;
  }
  .img-temple {
    height: 80vh;
    width: 94%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
  }
}
.img-temple {
  height: 80vh;
  width: 94%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 9px 15px 30px 15px;
}
@media screen and (max-width: 1024px) {
  .img-temple {
    height: 42vh;
    width: 94%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
@media screen and (max-width: 1000px) {
  .img-temple {
    height: 80vh;
    width: 94%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
@media screen and (max-width: 767px) {
  .img-temple {
    height: 55vh;
    width: 94%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
@media screen and (max-width: 490px) {
  .img-temple {
    height: 45vh;
    width: 94%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
@media screen and (max-width: 465px) {
  .img-temple {
    height: 40vh;
    width: 94%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
@media screen and (max-width: 390px) {
  .img-temple {
    height: 35vh;
    width: 94%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
@media screen and (max-width: 330px) {
  .img-temple {
    height: 23vh;
    object-fit: cover;
    width: 94%;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
@media screen and (max-width: 313px) {
  .img-temple {
    height: 15vh;
    width: 94%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
@media screen and (max-width: 768px) {
  .img-temple {
    width: 94%;
    height: 38vh;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 9px 15px 30px 15px;
    background-image: url("/blackcandleservice.jpg");
  }
}
.textdiv-temple {
  margin-left: 1px;
}
.heading-temple {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  text-align: left;
  color: #000000;
  line-height: 1.15em;
  width: 100%;
}
.pera-temple {
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8125em;
  padding: 8px;
}
.important-temple {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.8125em;
  color: #000000;
  margin-bottom: 4px;
}
.points-temple {
  display: inline;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal !important;
  font-size: 0.8125em;
  color: #000000;
}
.link-temple {
  color: #d85427;
  font-family: "Open Sans Condensed", sans-serif;
  white-space: normal !important;
  text-decoration: underline;
  font-weight: normal;
}
.link-temple:hover {
  color: #d85427;
}
.input-temple {
  width: 124%;
  height: 30px;
  border: 1px solid rgb(219, 218, 218);
}
.colo-temple {
  background-color: rgb(236, 234, 234);
}
.banishinput-temple {
  width: 100%;
  border: 1px solid rgb(219, 218, 218);
}
.input-temple::placeholder {
  color: rgb(197, 196, 196);
  font-size: small;
  /* border: rgb(197, 196, 196); */
}
.coninfo-temple {
  font-weight: bold;
  font-size: 1em;
}
.inputinfo-temple {
  font-size: 11px;
  font-weight: 350;
  width: 100%;
}
.start-temple {
  color: rgb(138, 21, 21);
}
.in-temple {
  margin-bottom: 0px;
  color: black;
}
.inputline-temple {
  width: 126%;
}
.ine-temple {
  width: 100%;
  margin-bottom: 0px;
  color: black;
}
.socialmedia-temple {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 0.875em;
  font-weight: bolder;
  line-height: 1.3em;
  text-align: left;
  color: black;
}
.socialmedia-temple + input {
  border: 1px solid rgb(219, 218, 218);
}
.numero_option.temple {
  margin-left: 15px;
  font-size: 14px;

  margin-bottom: 15px;
}
.numero_option.temple label {
  color: black;
  font-weight: bold;
}
.addperson-temple {
  background-color: rgb(255, 255, 255);
  margin: 1% 0% 1% 1%;
  width: 98%;
}
.addpersonodd-temple {
  background-color: rgb(240, 236, 236);
  margin: 1% 0% 1% 1%;
  width: 98%;
}
.material-icons {
  border-radius: 15px;
  background-color: #d85427;
  color: white;
}
.crossbutton-temple {
  margin: 2% 0% 1% 0%;
  width: 2%;
  z-index: 100;
  margin-left: 8px;
}
.personnames-temple {
  margin: 1% 5%;
}
.person-temple {
  height: 10px;
  display: inline-flex;
}
.candleinfo-temple {
  margin: 1% 6%;
  margin-top: 20px;
}
.addcandleodd-temple {
  background-color: rgb(235, 231, 231);
  width: 89%;
  margin: auto;
}
.addcandleeven-temple {
  background-color: rgb(240, 235, 235);
  width: 89%;
  margin: auto;
}
.dropdown-toggle {
  width: 100%;
  height: 30px;
  font-size: 12px;
}
.candaledd-temple {
  margin-top: 7%;
  margin-left: -2%;
}
.caret-temple {
  margin-left: 95%;
}
button[type="submit"].addcandlebtn-temple {
  background-color: #d85427;
  border-color: #d85427;
  color: white;
  display: inline-flex;
  margin-bottom: 20px;
  font-weight: 200;
  font-family: "Open Sans Condensed", Helvetica, sans-serif;
}
.addcandlebtn-temple {
  background-color: #d85427;
  border-color: #d85427;
  color: white;
  display: inline-flex;
  margin-bottom: 20px;
  font-weight: 200;
  font-family: "Open Sans Condensed", Helvetica, sans-serif;
}
.addcandlebtn-temple:hover {
  background-color: #d85427;
  border-color: #d85427;
}
.hfont-temple {
  font-size: larger;
}
.h-temple {
  font-size: 12px;
}
.dropdown-menu li {
  background-color: #d85427;
  border: 1px solid white;
  color: white;
}
.error-temple {
  color: rgb(211, 8, 8);
  font-size: smaller;
  margin-bottom: auto;
  margin-top: auto;
  font-weight: bolder;
}
.radio {
  height: 26px;
  margin-left: 1px;
}
.fieldset-temple {
  margin-bottom: 5%;
}
.picupload-temple {
  display: none;
}
.imgupload-temple {
  width: 50px;
  height: 35px;
}
.imglogo-temple {
  width: 10%;
}
.imgrow-temple {
  width: 100%;
  height: 40;
  background-color: rgb(218, 214, 214);
  text-align: right;
  margin-left: 0%;
}
.iconss-temple {
  margin: 5px 2px 2px 2px;
}
.money-temple {
  float: right;
}
.coloo-temple {
  float: right;
}

.margin-top-20 {
  margin-top: 23px;
}
.temple-datepicker {
  width: 170px;
  margin-top: 10px;
}
.loading {
  width: 2.5em;
  height: 3em;
  border: 3px solid transparent;
  border-top-color: rgb(255, 123, 0);
  border-bottom-color: #000000;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}
@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}
.reset-temple {
  width: 100%;
  height: 50px;
  text-align: center;
  color: white;
  font-size: larger;
  background-color: #d85427;
}
input[disabled] {
  background-color: white;
  color: rgb(46, 46, 46);
}
.temple-disabled {
  color: white;
  background-color: rgb(175, 174, 174);
}
.temple-disabled :hover {
  color: white;
  background-color: rgb(175, 174, 174);
  border-color: rgb(175, 174, 174);
}
.temple-candmoney {
  font-size: larger;
  font-weight: bolder;
  color: #000000;
}
