body {
  background: #0e101c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}
h3 {
  text-align: center;
  color: #fff;
}

form {
  max-width: 500px;
  margin: 0 auto;
}

h4,
span {
  color: #fff;
}

h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

p {
  color: #bf1650;
}

p::before {
  display: inline;
  content: "⚠ ";
}

input,
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

button[type="submit"],
input[type="submit"],
button[type="pay"] {
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

button[type="submit"]:hover,
input[type="submit"]:hover,
button[type="pay"]:hover {
  background: #bf1650;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active,
button[type="pay"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}
.paynow {
  width: 100%;
}
.reset {
  width: 30vw;
  margin: 0 auto;
}
input:disabled,
textarea:disabled {
  opacity: 0.4;
}

input[type="button"]:hover,
textarea:hover {
  transition: 0.3s all;
}

button[type="submit"],
input[type="button"],
input[type="submit"],
textarea {
  -webkit-appearance: none;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

button[type="button"] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

hr {
  margin-top: 30px;
}

button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}
div.loader {
  width: 2.5em;
  height: 3em;
  border: 3px solid transparent;
  border-top-color: #fc2f70;
  border-bottom-color: #fc2f70;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}

@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}

.numero_wrap {
  display: flex;
  flex-direction: column;
  padding: 0 100px;
}
.numero_option {
  display: flex;
  align-items: baseline;
}
.numero_option input {
  width: auto;
}

input[type="radio"] {
  cursor: pointer;
}

.modal-window {
  position: fixed;
  background-color: rgba(28, 40, 77, 0.84);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-window > div {
  width: 80%;
  padding: 10px 25px;
  position: absolute;
  top: 50%;
  height: 80vh;
  overflow: scroll;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2em;
  line-height: 40px;
  box-sizing: border-box;
  word-spacing: 2px;
  background: #ffffff;
}
.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  cursor: pointer;
  width: 70px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}

.knw_more {
  margin-left: 10px;
  color: #bf1650;
  text-align: right;
  border: 2px solid #bf1650;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
  display: inline-block;
}

.ntitle {
  text-align: center;
  display: inline-block;
  /* margin: 0 auto; */
  width: 100%;
}

input[disabled] {
  color: #fff;
}

.datepicker{
  background: #fff;
  margin: 0 auto;
}
.datepicker .DayPicker{
  margin: 0 auto;
  width: 95%;
}
